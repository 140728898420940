<template>
  <!--Header Component-->
  <Header id="header">
    <div class="container content">
      <div>
        <ul>
          <li>
            <img
              src="@/assets/icons/vlive_logo.png"
              height="29px"
              width="64px"
              alt=""
            />
          </li>
        </ul>
      </div>
      <div>
        <h5>Search content</h5>
      </div>
      <div>
        <h5>Signup Content</h5>
      </div>
    </div>
  </Header>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
#header {
  background: $clr-header-bg;

  .content {
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    li {
      padding: 0.5rem;
    }
  }
}
</style>
